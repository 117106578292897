// This file is a mapping on the frontend for the different partner keys
// These keys match the ones in the `api` project
// todo: figure out a more secure solution for this.

const API_KEYS: { [key: string]: string } = {
  demo: '176258a3-af57-4dbd-9e97-d1390de41f57',
  insuramatch: '5cd6a625-67a6-4256-8100-8bfb76d2fb59',
  insuritas: '0bde3f33-c910-4f10-973d-00d96675e921',
  dp: '933df275-7bcd-41d8-af9d-d688a72e79ed',
  matic: '452832f6-d635-4b23-a036-c7d84ed4aed1',
  vivint: '139937a6-556c-45e1-8adb-8caffbcd59c9',
  simplyioa: '98d49bb9-5ee0-4994-92b1-288a4b18e4b8',
  simplyioa_dev: 'a3299373-9150-49bf-9b2c-0a61f84f3d29',
  thezebra: 'df33cb71-e013-4d4c-9875-5ad83fe7328d',
};

export const PARTNER_NAMES: { [key: string]: string } = {
  demo: 'Your Agent',
  insuramatch: 'Insuramatch',
  insuritas: 'Insuritas',
  dp: 'DealerPolicy',
  matic: 'Matic',
  vivint: 'Vivint',
  simplyioa: 'SimplyIOA',
  simplyioa_dev: 'SimplyIOA Dev',
  thezebra: 'The Zebra',
};

const APPLICATION_IDS: { [key: string]: string } = {
  vivint: '718731c0-4e3d-4d1d-8560-a084a10cdf60',
  thezebra: 'ba3694d3-e764-4ad1-8f3d-86ada0eddb19',
};

export type PartnerInfo = {
  apiKey: string;
  key: string;
  name: string;
  applicationId: string;
};

export function getPartnerInfoByNameOrPath(partner: string): PartnerInfo {
  if (partner.includes('/')) {
    const splitPartnerPath = partner.split('/');
    const partnerPathChunk =
      splitPartnerPath?.length > 1 ? splitPartnerPath[1] : null;
    if (partnerPathChunk) {
      partner = partnerPathChunk;
    }
  }

  if (!Reflect.has(PARTNER_NAMES, partner)) {
    partner = '';
  }

  return {
    apiKey: API_KEYS[partner] || '',
    key: partner,
    name: PARTNER_NAMES[partner] || '',
    applicationId: APPLICATION_IDS[partner] || '',
  };
}
