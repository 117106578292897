import { dispatch } from '../store';
import { loginFailure } from '../modules/applicant/redux/auth';

declare let TrellisConnect: any | undefined;

let script: HTMLScriptElement | null = null;
let instance: { open: any } | null = null;
let globalPartnerKey: string;
let globalUserId: string;

const clientConfig = {
  disable,
  enable,
  instance,
  open,
};

export default clientConfig;

async function enable(
  clientId: string,
  partnerKey: string,
  requestId: string,
  applicationId?: string,
): Promise<boolean> {
  script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.trellisconnect.com/sdk/v1.1/trellis-connect.js';

  globalPartnerKey = partnerKey;
  globalUserId = requestId;

  document.body.appendChild(script);

  const didFindInstance = await waitForInstance();
  if (!didFindInstance) return false;

  instance = TrellisConnect.configure({
    client_id: clientId,
    application_id: applicationId,
    userId: requestId,
    onSuccess: handleTrellisSuccess,
    onFailure: handleTrellisFailure,
    onClose: handleTrellisClose,
    track: handleTrellisAnalyticsTrack,
    page: handleTrellisAnalyticsPage,
    features: 'loginv1,nostickystate',
    // A URL that is called asynchronously by the Trellis API when it has completed pulling insurance data.
    // webhook: 'https://api.myserver.com/trellisUpdate',
  });

  return true;
}

function disable() {
  if (script) {
    const parent = script.parentNode;
    if (parent) {
      parent.removeChild(script);
    }
  }

  script = null;
  instance = null;
}

function open(): boolean {
  if (!instance) return false;

  instance.open();
  analytics.track('Opened Trellis JS SDK', { partner: globalPartnerKey });
  return true;
}

async function waitForInstance(): Promise<boolean> {
  let attempt = 1;

  // 30 seconds
  while (attempt < 120) {
    if (Reflect.has(window, 'TrellisConnect')) {
      return true;
    }

    attempt++;
    await new Promise(resolve => setTimeout(resolve, 250));
  }

  return false;
}

// onSuccess(accountId, metadata)
// Called when TrellisConnect has completed retrieving policy information from the user.
// The function is passed in an accountId and a metadata object. The accountId can be
// used by application server, combined with your Trellis API-SECRET-KEY to pull policy data.
function handleTrellisSuccess(accountId: string, metadata: any) {
  // console.log('handleTrellisSuccess accountId', accountId)
  // console.log('handleTrellisSuccess metadata', metadata)

  analytics.track('Successful Auth Through Trellis JS SDK', {
    accountId,
    partner: globalPartnerKey,
  });

  const agentUrl =
    process.env.NODE_ENV !== 'development'
      ? 'https://agent.trellisconnect.com'
      : 'http://localhost:3003';

  window.location.href = `${agentUrl}/${globalPartnerKey}/${accountId}?u=${globalUserId}`;
}

// onFailure()
// Called each time the user attempts to authenticate with their insurer and fails.
function handleTrellisFailure() {
  // console.log('handleTrellisFailure')

  analytics.track('Failed to Auth Through Trellis JS SDK', {
    partner: globalPartnerKey,
  });

  dispatch(loginFailure(new Error('Unable to authenticate')));
}

// onClose()
// Called when the user closes the modal dialog -- either when they have
// successfully loaded their policies (potentially after an onSuccess() call) or by
// clicking the "X" button in the top right of the modal.
function handleTrellisClose() {
  // console.log('handleTrellisClose')
}

// track(event, params)
// Similar in meaning to segment.com's analytics.track() call for events occuring
// inside the Trellis widget.
// event -- the name of the analytics tracking event
// params -- a dictionary object of additional event data
function handleTrellisAnalyticsTrack(event: any, params: any) {
  // console.log('handleTrellisAnalyticsTrack event', event)
  // console.log('handleTrellisAnalyticsTrack params', params)
}

// page(page, params)
// Similar in meaning to segment.com's analytics.page() call for pageviews occuring
// inside the Trellis widget.  We fire a page() call for every widget screen.
// page -- the name of the page visited
// params -- a dictionary object of additional pageview data
function handleTrellisAnalyticsPage(page: any, params: any) {
  // console.log('handleTrellisAnalyticsPage page', page)
  // console.log('handleTrellisAnalyticsPage params', params)
}
