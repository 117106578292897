import { Policy } from '@trellisconnect/types';

const UNLIMITED_COVERAGE_LIMIT = 999999999999999;

export function centsToDollars(cents: number): string {
  const str = String(cents);
  return `$${str.slice(0, -2)}.${str.slice(-2)}`;
}

export function centsToCurrency(
  cents?: number,
  ignoreCents = true,
): string | null {
  if (!cents && cents !== 0) {
    return null;
  }

  if (cents === UNLIMITED_COVERAGE_LIMIT) {
    return 'Unlimited';
  }

  const dollars = cents / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: ignoreCents ? 0 : 2,
    maximumFractionDigits: ignoreCents ? 0 : 2,
  });
}

export const responsiveWidth = '575px';
export const policyListMaxWidth = '700px';

const padDate = (input: number) => input.toString().padStart(2, '0');
export const dateFormat = (date: Date): string =>
  [
    date.getFullYear(),
    padDate(date.getMonth() + 1),
    padDate(date.getDate()),
  ].join('-');

export const isPolicyExpired = (
  policy: Policy | undefined,
  currentDate = dateFormat(new Date()),
) => {
  if (!policy?.renewalDate) {
    return false;
  }
  return policy?.renewalDate && policy?.renewalDate < currentDate;
};

export const isPolicyFuture = (
  policy: Policy | undefined,
  currentDate = dateFormat(new Date()),
) => {
  return policy?.issueDate ? policy?.issueDate > currentDate : false;
};

export const isPolicyActive = (policy: Policy | undefined): boolean => {
  return Boolean(policy?.active);
};

export const isPolicyCanceled = (
  policy: Policy | undefined,
  currentDate: string = dateFormat(new Date()),
): boolean => {
  if (!policy?.canceledDate) {
    return false;
  }
  return policy.canceledDate ? currentDate > policy.canceledDate : false;
};

export const hasActivePolicy = (
  policies: Policy[],
  currentDate = dateFormat(new Date()),
): boolean => {
  return policies.some(
    policy =>
      !isPolicyFuture(policy, currentDate) &&
      !isPolicyExpired(policy, currentDate) &&
      !isPolicyCanceled(policy, currentDate) &&
      isPolicyActive(policy),
  );
};
