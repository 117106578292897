import { Action, AsyncState } from '../../util';
import { call, delay, put, takeEvery } from 'redux-saga/effects';

import { Policy } from '@trellisconnect/types';
import { SagaIterator } from 'redux-saga';
import env from '../../../lib/env';
import { getIssuer } from '../../issuers/redux/list';
import request from '../../../lib/request';

// GET

const GET_INIT = 'policies/list/GET';
const GET_PENDING = 'policies/list/GET_PENDING';
const GET_SUCCESS = 'policies/list/GET_SUCCESS';
const GET_FAILURE = 'policies/list/GET_FAILURE';

type GetInitAction = {
  type: typeof GET_INIT;
  apiKey: string;
  payload: string;
};
type GetPendingAction = {
  type: typeof GET_PENDING;
};
type GetSuccessAction = {
  type: typeof GET_SUCCESS;
  payload: Policy[];
};
type GetFailureAction = {
  type: typeof GET_FAILURE;
  payload: Error;
};

export function getPolicies(accountId: string, apiKey: string): GetInitAction {
  return {
    type: GET_INIT,
    apiKey: apiKey,
    payload: accountId,
  };
}
function getPoliciesPending(): GetPendingAction {
  return { type: GET_PENDING };
}
function getPoliciesSuccess(policies: Policy[]): GetSuccessAction {
  return {
    type: GET_SUCCESS,
    payload: policies,
  };
}
function getPoliciesFailure(error: Error): GetFailureAction {
  return {
    type: GET_FAILURE,
    payload: error,
  };
}
export function getPolicyTypeReadable(policyType: string): string | null {
  const policyTypes: { [index: string]: string } = {
    PERSONAL_AUTO: 'Personal Auto',
    HOMEOWNERS: 'Homeowners',
    RENTERS: 'Renters',
    OTHER: 'Other',
  };

  const selectedPolicyType = policyTypes[policyType];

  return selectedPolicyType || null;
}

type PoliciesApiPayload = {
  clientId: string;
  accountId: string;
};

type PoliciesApiResponse = PoliciesApiResponseStatus & PoliciesApiResponseData;
type PoliciesApiResponseStatus = {
  error: string;
  success: false;
};
type PoliciesApiResponseData = {
  data: Policy[];
};

export function* getPoliciesTask(action: GetInitAction): SagaIterator {
  yield put(getPoliciesPending());

  try {
    const accountId = action.payload;
    if (!accountId) throw new Error('Unable to get policies. No accountId');

    yield put(getIssuer(accountId, action.apiKey));

    let missingPolicy = true;

    while (missingPolicy) {
      try {
        const policieisApiPayload: PoliciesApiPayload = {
          clientId: action.apiKey,
          accountId,
        };
        const apiResponse: PoliciesApiResponse = yield call(
          request.post,
          env.ACCOUNT_POLICIES_RETRIEVAL_URL,
          policieisApiPayload,
        );

        if (!apiResponse.success || apiResponse.error) {
          throw new Error(apiResponse.error);
        }

        yield put(getPoliciesSuccess(apiResponse.data));
        missingPolicy = false;
      } catch (err) {
        if ((err as Error).message !== 'POLICIES NOT READY YET') {
          throw err;
        }
      }

      yield delay(2000);
    }
  } catch (err) {
    yield put(getPoliciesFailure(err as Error));
  }
}

export function* getPoliciesWatcher(): SagaIterator {
  yield takeEvery(GET_INIT, getPoliciesTask);
}

// CLEAR

const CLEAR = 'policies/list/CLEAR';

type ClearAction = { type: typeof CLEAR };

export function clearPolicies(): ClearAction {
  return { type: CLEAR };
}

// REDUCER

type StorePolicies = {
  data: Policy[];
  get: AsyncState & {
    hasRun: boolean;
  };
};
const initialState: StorePolicies = {
  // the alternate line here is for having policies right away in dev
  data: [],
  // data: [require('./list.dev').default(1), require('./list.dev').default(2)],
  get: {
    error: null,
    hasRun: false,
    isLoading: false,
  },
};

export function listReducer(state: StorePolicies = initialState, action: Action): StorePolicies {
  switch (action.type) {
    // GET
    case GET_PENDING:
      return { ...state, get: { isLoading: true, hasRun: false, error: null } };

    case GET_SUCCESS:
      return {
        ...state,
        get: { isLoading: false, hasRun: true, error: null },
        data: action.payload,
      };

    case GET_FAILURE:
      return {
        ...state,
        get: { isLoading: false, hasRun: true, error: action.payload },
      };

    case CLEAR:
      return initialState;

    default:
      return state;
  }
}
