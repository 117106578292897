import { all } from 'redux-saga/effects';

import { getIssuerWatcher } from './redux/list';

import { issuersReducer } from './redux';

function* issuers() {
  yield all([getIssuerWatcher()]);
}
export { issuers, issuersReducer };
