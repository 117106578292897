import env from './env';

let API_ROOT = env.API_BASE_URL;
if (API_ROOT[API_ROOT.length - 1] !== '/') {
  API_ROOT = `${API_ROOT}/`;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type PostData = Object | Array<any>;

let apiKey = '';

const requestConfig = {
  get: (url: string) => makeRequest(url, 'GET'),
  post: (url: string, postData: PostData) => makeRequest(url, 'POST', postData),
  put: (url: string, postData: PostData) => makeRequest(url, 'PUT', postData),
  delete: (url: string) => makeRequest(url, 'DELETE'),
  setup,
};

export default requestConfig;

function setup(newApiKey: string) {
  apiKey = newApiKey;
}

function makeRequest(url: string, method: string, postData?: PostData): Promise<any> {
  // Remove leading slash if it's present
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  let apiUrl = '';
  let extraHeaders = {};

  // If a full URL is passed, then don't prefix it or pass our internal headers
  if (url.startsWith('http')) {
    apiUrl = url;
  } else {
    apiUrl = API_ROOT + url;
    extraHeaders = {
      'Content-Type': 'application/json',
      'X-API-Client-Id': apiKey,
    };
  }

  // Config what type of http method
  const config: any = {
    headers: {
      Accept: 'application/json',
      ...extraHeaders,
    },
    method,
  };
  // Add post data if necessary
  if (method === 'POST') {
    config.body = JSON.stringify(postData);
  }

  return new Promise((resolve, reject) => {
    let hasError = false;

    fetch(apiUrl, config)
      .then(res => {
        if (!res.ok) {
          hasError = true;
        }

        return res.json();
      })
      .then(json => {
        if (!hasError) {
          resolve(json);
        } else {
          reject(json);
        }
      })
      .catch(err => {
        console.log(`Failed to get resource from ${apiUrl}. Error:`, err);
        reject(err);
      });
  });
}
