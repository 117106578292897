import localforage from 'localforage';

// todo: remove specifying this later. Just doing to avoid any possible issue
localforage.setDriver(localforage.LOCALSTORAGE);

export default localforage;

// key: account
export type AccountData = {
  accountId: string;
  timestamp: number;
};

// key: device
export type DeviceData = string;
