import * as React from 'react';

import NeedsToLogin from './needsToLogin';
import { Store } from '../modules/rootReducer';
import { bootstrap } from '../modules/app/redux/bootstrap';
import { connect } from 'react-redux';
import { dispatch } from '../store';

type AppProps = {
  areTherePolicies: boolean;
  bootstrapError: string | null;
  hasPolicyFetchRun: boolean;
  isWaitingForPolicy: boolean;
  requestId: string;
  deviceId: string;
};

function App({
  requestId,
  deviceId,
  areTherePolicies,
  hasPolicyFetchRun,
  isWaitingForPolicy,
  bootstrapError,
}: AppProps) {
  React.useEffect(() => {
    dispatch(bootstrap());
    analytics.identify(requestId, { deviceId: deviceId });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center">
      {!areTherePolicies && !hasPolicyFetchRun && !isWaitingForPolicy ? (
        <NeedsToLogin />
      ) : null}

      {bootstrapError ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="w-full border border-gray-200 bg-white flex items-center flex-col max-w-lg rounded">
            <header className="p-4 border-b border-b-gray-200 w-full text-left">
              Error
            </header>
            <div className="flex items-center w-full py-6 px-4">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                className="text-4xl text-red-600 mr-4"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill="red"
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                ></path>
                <path
                  fill="#ffeae6"
                  d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm-32 156c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"
                ></path>
                <path
                  fill="red"
                  d="M488 576h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8zm-24 112a48 48 0 1 0 96 0 48 48 0 1 0-96 0z"
                ></path>
              </svg>
              <div>{bootstrapError}</div>
            </div>
            <footer className="p-4 border-t border-t-gray-200 w-full flex justify-end">
              <button
                className="rounded px-4 py-2 bg-trellis-blue text-white text-center"
                onClick={reloadPage}
              >
                Reload Page
              </button>
            </footer>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function reloadPage() {
  window.location.assign(window.location.href);
}

function mapStateToProps(state: Store) {
  return {
    areTherePolicies:
      state.policies.list.data !== undefined &&
      !!state.policies.list.data.length,
    bootstrapError: state.app.bootstrap.error,
    requestId: state.applicant.data.requestId,
    deviceId: state.applicant.data.deviceId,
    hasPolicyFetchRun: state.policies.list.get.hasRun,
    isWaitingForPolicy: state.policies.list.get.isLoading,
  };
}

export default connect(mapStateToProps)(App);
