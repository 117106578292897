import { PartnerInfo, getPartnerInfoByNameOrPath } from '../../../lib/partners';
import { call, put, takeEvery } from 'redux-saga/effects';
import localDb, { AccountData, DeviceData } from '../../../lib/localDb';
import { login, loginSuccess } from '../../applicant/redux/auth';

import { Action } from '../../util';
import { SagaIterator } from 'redux-saga';
import { dispatch } from '../../../store';
import { getPolicies } from '../../policies';
import sharedRequest from '../../../lib/request';
import trellisClient from '../../../lib/trellisClient';
import { updateAgentData } from '../../agent';
import { updateApplicantData } from '../../applicant/redux';
import uuid from 'uuid/v4';

const THIRTY_SECONDS = 1000 * 30;

const INIT = 'app/bootstrap';
const PENDING = 'app/bootstrap/PENDING';
const SUCCESS = 'app/bootstrap/SUCCESS';
const FAILURE = 'app/bootstrap/FAILURE';

type InitAction = {
  type: typeof INIT;
};
type PendingAction = {
  type: typeof PENDING;
};
type SuccessAction = {
  type: typeof SUCCESS;
};
type FailureAction = {
  type: typeof FAILURE;
  payload: Error;
};

export function bootstrap(): InitAction {
  return { type: INIT };
}
function bootstrapPending(): PendingAction {
  return { type: PENDING };
}
function bootstrapSuccess(): SuccessAction {
  return { type: SUCCESS };
}
function bootstrapFailure(error: Error): FailureAction {
  return {
    type: FAILURE,
    payload: error,
  };
}

function* bootstrapTask(): SagaIterator {
  yield put(bootstrapPending());

  try {
    const params = new URLSearchParams(document.location.search);
    // Check for request id (u for user)
    const requestId = params.get('u') || '';
    if (!requestId) {
      analytics.page('No Request ID Provided (404 ish)');
      throw new Error('No Request ID specified');
    }
    // Check for partner
    const partner = params.get('p') || '';
    if (!partner) {
      analytics.page('No Partner Provided (404 ish)');
      throw new Error('No partner token specified');
    }
    const partnerInfo: PartnerInfo = getPartnerInfoByNameOrPath(partner);
    if (!partnerInfo.apiKey) {
      throw new Error(`Unable to find partner based on name: '${partner}'`);
    }

    // Check for agent email (a for agent) [optional]
    const agentEmail = params.get('a') || '';

    // Set up shared request lib
    sharedRequest.setup(partnerInfo.apiKey);

    // Try and get device ID out of local storage
    let deviceId: string = '';
    try {
      deviceId = ((yield call(localDb.getItem, 'device')) as unknown) as DeviceData;
    } catch (e) {
      console.log('Unable to GET device ID from local storage');
    }

    // Generate a device ID and store if there isn't one
    if (!deviceId) {
      deviceId = uuid();
      try {
        yield call([localDb, localDb.setItem], 'device', deviceId);
      } catch (e) {
        console.log('Unable to SET device ID to local storage');
      }
    }

    // Try and get account ID out of local storage
    let accountId: string = '';
    try {
      const accountData = ((yield call(localDb.getItem, 'account')) as unknown) as AccountData;
      // todo: look at making sure this works. This probably also needs to tie into the `hasRun` logic now
      if (accountData && Date.now() - accountData.timestamp < THIRTY_SECONDS) {
        accountId = accountData.accountId;
        dispatch(loginSuccess(accountId));
        // You can't just dispatch this immediately even though the Trellis client called this success callback.
        // The delay in this setTimeout() is required for the backend to actually register the authentication
        setTimeout(() => dispatch(getPolicies(accountId, partnerInfo.apiKey)), 1000);

        analytics.track('Valid Account ID found in local storage. Skipping Trellis JS SDK', {
          accountId,
          partner: partnerInfo.key,
        });
      }
    } catch (e) {
      console.log('Unable to GET account ID from local storage');
    }

    if (!accountId) {
      // Pull in Trellis script and wait for it to set up
      const didEnable = yield call(
        trellisClient.enable,
        partnerInfo.apiKey,
        partnerInfo.key,
        requestId,
        partnerInfo.applicationId,
      );
      if (!didEnable) {
        trellisClient.disable();
        throw new Error('Trellis client did not load in time');
      }

      analytics.track('Automatically Open Trellis JS SDK', { partner: partnerInfo.key });

      // Launch user login flow
      yield put(login());
    }

    analytics.identify(requestId, { deviceId });
    analytics.page('Landing', { partner: partnerInfo.key, agentEmail });

    yield put(updateApplicantData({ deviceId, requestId }));
    yield put(updateAgentData({ partner: partnerInfo, email: agentEmail }));
    yield put(bootstrapSuccess());
  } catch (err) {
    yield put(bootstrapFailure(err as Error));
  }
}

export function* bootstrapWatcher(): SagaIterator {
  yield takeEvery(INIT, bootstrapTask);
}

type StoreAppBootstrap = {
  error: string | null;
  isDone: boolean;
};
const initialState: StoreAppBootstrap = {
  error: null,
  isDone: false,
};

export function bootstrapReducer(state: StoreAppBootstrap = initialState, action: Action): StoreAppBootstrap {
  switch (action.type) {
    case SUCCESS:
      return {
        error: null,
        isDone: true,
      };
    case FAILURE:
      return {
        error: action.payload.message,
        isDone: true,
      };
    default:
      return state;
  }
}
