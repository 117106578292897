import { LOGIN_SUCCESS, LOGOUT, authReducer } from './auth';

import { Action } from '../../util';
import { combineReducers } from 'redux';

// UPDATE

const UPDATE = 'applicant/UPDATE';

type UpdateAction = {
  type: typeof UPDATE;
  payload: UpdateActionPayload;
};
type UpdateActionPayload = {
  deviceId: string;
  requestId: string;
};

export function updateApplicantData(data: UpdateActionPayload): UpdateAction {
  return {
    type: UPDATE,
    payload: data,
  };
}

// REDUCER

type ApplicantData = {
  accountId: string;
  deviceId: string;
  requestId: string;
};
type StoreApplicant = ApplicantData;

const initialState: StoreApplicant = {
  accountId: '',
  deviceId: '',
  requestId: '',
};

function applicantReducer(state: StoreApplicant = initialState, action: Action): StoreApplicant {
  switch (action.type) {
    case UPDATE:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        accountId: '',
      };

    default:
      return state;
  }
}

export default combineReducers({
  auth: authReducer,
  data: applicantReducer,
});
