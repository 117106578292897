import './index.css';
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime';

import App from './components';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import store from './store';

const rootElement = document.getElementById('root');
const rootComponent = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(rootComponent, rootElement);
