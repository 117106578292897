import * as React from 'react';

import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';
import trellisClient from '../lib/trellisClient';

function NeedsToLogin({ partnerKey }: { partnerKey: string }) {
  function restartLoginFlow() {
    analytics.track('Clicked Share My Policy', { partner: partnerKey });
    trellisClient.open();
  }

  return (
    <div className="flex justify-center items-center flex-col m-auto max-w-lg text-center">
      <h2 className="mb-5 font-bold text-xl text-gray-label">Policy Lookup</h2>
      <p className="mb-10 text-lg text-gray-label">
        Log in here to view your policy information
      </p>
      <button
        className="py-6 px-8 text-white bg-trellis-blue rounded-full text-lg text-center outline-none"
        onClick={restartLoginFlow}
      >
        Share My Policy With My Agent
      </button>
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    partnerKey: state.agent.data.partner.key,
  };
}

export default connect(mapStateToProps)(NeedsToLogin);
