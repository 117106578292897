import { agentReducer } from './agent';
import app from './app/redux';
import applicant from './applicant/redux';
import { combineReducers } from 'redux';
import { issuersReducer } from './issuers';
import { policiesReducer } from './policies';

const rootReducer = combineReducers({
  agent: agentReducer,
  app,
  applicant,
  issuers: issuersReducer,
  policies: policiesReducer,
});

export type Store = ReturnType<typeof rootReducer>;

export default rootReducer;
