import { combineReducers } from 'redux';

import { listReducer } from './list';

export const policiesReducer = combineReducers({
  list: listReducer,
});

export type StorePolicies = ReturnType<typeof policiesReducer>;

export * from './list';
