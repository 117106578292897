import { Action } from '../../util';
import { PartnerInfo } from '../../../lib/partners';

// UPDATE

const UPDATE = 'agent/UPDATE';

type UpdateAction = {
  type: typeof UPDATE;
  payload: UpdateActionPayload;
};
type UpdateActionPayload = {
  partner?: PartnerInfo;
  email?: string;
};

export function updateAgentData(data: UpdateActionPayload): UpdateAction {
  return {
    type: UPDATE,
    payload: data,
  };
}

// REDUCER

type AgentData = {
  partner: PartnerInfo;
  email: string;
};

export type StoreAgent = {
  data: AgentData;
};

const initialState: StoreAgent = {
  data: {
    partner: {
      apiKey: '',
      key: '',
      name: '',
      applicationId: '',
    },
    email: '',
  },
};

export function agentReducer(
  state: StoreAgent = initialState,
  action: Action,
): StoreAgent {
  switch (action.type) {
    // UPDATE
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
