import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { Action } from '../../util';
import { SagaIterator } from 'redux-saga';
import { Store } from '../../rootReducer';
import { env } from '../../../lib';
import request from '../../../lib/request';
import trellisClient from '../../../lib/trellisClient';

// LOGIN

const LOGIN_INIT = 'applicant/auth/LOGIN';
const LOGIN_PENDING = 'applicant/auth/LOGIN_PENDING';
export const LOGIN_SUCCESS = 'applicant/auth/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'applicant/auth/LOGIN_FAILURE';

type LoginInitAction = {
  type: typeof LOGIN_INIT;
};
type LoginPendingAction = {
  type: typeof LOGIN_PENDING;
};
type LoginSuccessAction = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    accountId: string;
  };
};
type LoginFailureAction = {
  type: typeof LOGIN_FAILURE;
  payload: Error;
};

export function login(): LoginInitAction {
  return { type: LOGIN_INIT };
}
function loginPending(): LoginPendingAction {
  return { type: LOGIN_PENDING };
}
export function loginSuccess(accountId: string): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      accountId,
    },
  };
}
export function loginFailure(error: Error): LoginFailureAction {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
}

type AssociateAPIPayload = {
  accountId: string;
  clientId: string;
  requestId: string;
};
type AssociateAPIResponse = {
  success: boolean;
  error: string;
};
function* loginTask(action: LoginInitAction): SagaIterator {
  yield put(loginPending());

  try {
    const didSucceed = trellisClient.open();
    if (!didSucceed) throw new Error('Trellis Client not available');

    const accountAction: LoginSuccessAction = yield take(LOGIN_SUCCESS);
    const { accountId } = accountAction.payload;

    try {
      const requestId: string = yield select((state: Store) => state.applicant.data.requestId);
      const apiKey: string = yield select((state: Store) => state.agent.data.partner.apiKey);

      const apiPayload: AssociateAPIPayload = {
        accountId,
        clientId: apiKey,
        requestId,
      };

      const response: AssociateAPIResponse = yield call(request.post, env.ASSOCIATE_ACCOUNT_URL, apiPayload);

      if (!response.success) {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log('Unable to associate account with requestId. Error: ', err);
    }
  } catch (err) {
    yield put(loginFailure(err as Error));
  }
}

export function* loginWatcher(): SagaIterator {
  yield takeEvery(LOGIN_INIT, loginTask);
}

// LOGOUT

export const LOGOUT = 'applicant/auth/LOGOUT';

type LogoutAction = {
  type: typeof LOGOUT;
};

export function logout(): LogoutAction {
  return { type: LOGOUT };
}

// REDUCER

type StoreAppAuth = {
  error: Error | null;
  isLoading: boolean;
};

const initialState: StoreAppAuth = {
  error: null,
  isLoading: false,
};

export function authReducer(state: StoreAppAuth = initialState, action: Action): StoreAppAuth {
  switch (action.type) {
    // LOGIN
    case LOGIN_PENDING:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case LOGIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    // LOGOUT
    case LOGOUT:
      return { ...state, isLoading: false, error: null };

    default:
      return state;
  }
}
