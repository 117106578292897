import { all } from 'redux-saga/effects';

import {
  policiesReducer,
  getPoliciesWatcher,
  getPolicies,
  clearPolicies,
} from './redux';

function* policies() {
  yield all([getPoliciesWatcher()]);
}

export { policies, policiesReducer, getPolicies, clearPolicies };
